/** profile/experiments.html */

.slices-search {
  max-width: 400px;
  margin: 0 auto;

  input {
    margin: 0;
  }
}


.slices-sort-wrapper {
  background-color: $white;
  padding: 24px;

  .slices-filter-wrapper{
    display: flex;
  }

  .filter {
    display: flex;
    align-items: center;

    .rc-checkbox >  input {
      margin: 0 2px 0 0;
    }
  }

  .name {
    display: inline-block;
    width: 75px;
    text-align: right;
    color: $dark-gray;
    padding-right: 8px;
  }

  label {
    display: inline;
  }

  .filter-checkbox-label {
    margin-right: 24px;
  }

  .projects-select {
    width: 300px;
    margin-right: 24px;
  }

  hr {
    border-bottom: 1px dashed $medium-gray;
  }

  .sort-button-group {
    display: inline-block;
  }
}

.slice-cards {
  margin: 24px 0;

  .slice-card {
    background-color: $white;

    .title {
      padding-right: 8px;
    }
  }
}

.no-slices {
  margin: 24px 0;
  background-color: $white;
  padding: 24px;
}

;@import "sass-embedded-legacy-load-done:588";