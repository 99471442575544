/** frontpage.html */

.frontpage-body {
  background: $white;

  header {
    position: relative;
    background: linear-gradient(
      45deg,
      $theme-color-primary,
      $theme-color-secondary
    );

    overflow: hidden;

    .top-bar {
      background: none;
    }

    padding-bottom: 96px;

    &::after {
      background: $white;
      bottom: 0;
      content: "";
      display: block;
      height: 100px;
      position: absolute;
      right: -10%;
      transform-origin: 100% 100%;
      transform: rotate(-2deg);
      width: 120%;
    }
  }

  .banner-wrapper {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    align-items: center;
    margin: 96px auto 24px auto;

    color: $dark-gray;

    @include breakpoint(medium down) {
      margin: 48px auto 24px auto;
    }
  }

  .banner-title-wrapper {
    max-width: 650px;
    padding: 48px;
    //flex: 1 0 auto;

    h1 {
      font-size: 54px;
      color: $white;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      font-size: 20px;
    }

    a.button {
      padding: 0.85em 1.5em;
      min-width: 120px;
      margin-right: 24px;

      &.primary {
        border: 1px solid $white;
      }

      &.hollow {
        border: 1px solid get-color(primary);
        background-color: $white;
      }
    }

    #login-button:before {
      @extend %fa-icon;
      @extend .fal;
      margin-right: 10px;
      content: fa-content($fa-var-sign-in);
    }

    #signup-button:before {
      @extend %fa-icon;
      @extend .fal;
      margin-right: 10px;
      content: fa-content($fa-var-id-card-alt);
    }

    @include breakpoint(medium down) {
      padding: 36px 24px;

      h1 {
        font-size: 36px;
      }

      p {
        font-size: 16px;
      }
    }
  }

  .banner-image-wrapper {
    img.fed4fire-userauth {
      width: 400px;
      background: radial-gradient(
        rgba(255, 255, 255, 1) 92%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    @include breakpoint(medium down) {
      width: 200px;
    }

    @include breakpoint(small down) {
      width: 150px;
    }
  }

  .frontpage-main {
    //margin: 0 0 48px 0;

    .frontpage-info-wrapper,
    .frontpage-about-wrapper {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto 96px;
      position: relative;

      h1 {
        text-align: center;
        margin-bottom: 1em;
      }

      h3,
      h5 {
        text-align: center;
        font-weight: 300;
        color: $black;
        letter-spacing: 0.6px;
      }

      h5 + p {
        margin-top: 6px;
      }

      p,
      ul {
        max-width: 680px;
        margin: 24px auto;

        em {
          color: darken($theme-color-primary, 10%);
          font-weight: 500;
        }
      }

      @include breakpoint(medium down) {
        margin: 48px auto;
      }

      .about-logos {
        max-width: 960px;
        margin: 24px auto;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .about-logo {
          flex: 1 1 auto;
          width: calc(100% / 2 - 96px);
          max-width: 400px;
          min-width: 260px;
          margin: 24px 48px;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            max-width: 200px;
          }

          p {
            text-align: center;
          }
        }
      }
    }

    //.features-container {
    //  margin: 24px 0;
    //  justify-content: space-around;
    //
    //  .cell {
    //    text-align: center;
    //    max-width: 250px;
    //  }
    //
    //  .feature {
    //    .feature-icon {
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //
    //      width: 100px;
    //      height: 100px;
    //      border-radius: 50px;
    //      box-shadow: 3px 3px 9px 1px $dark-gray;
    //      margin: 20px auto 32px;
    //    }
    //
    //    .feature-icon i {
    //      vertical-align: middle;
    //      font-size: 48px;
    //      display: block;
    //      border-radius: 80px;
    //      padding: 0.5em 0.6em;
    //    }
    //  }
    //
    //
    //  .feature:nth-child(odd) {
    //
    //    --fa-secondary-color: #{lighten($theme-color-secondary, 10%)};
    //    --fa-primary-color: #{lighten($theme-color-primary,10%)};
    //    --fa-secondary-opacity: 1.0;
    //  }
    //
    //  .feature:nth-child(even) {
    //
    //    --fa-secondary-color: #{lighten($theme-color-secondary, 20%)};
    //    --fa-primary-color: #{lighten($theme-color-primary,20%)};
    //    --fa-secondary-opacity: 1.0;
    //  }
    //}
  }
}

.slices-ri-offering {
  font-weight: bold;
  font-variant: small-caps;
}

;@import "sass-embedded-legacy-load-done:571";