/** auth/approval_pending.html */

.pending-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-size: 100px;
  background-color: $orange-5;
  color: white;

  height: 150px;
  width: 150px;
  border-radius: 75px;
}
;@import "sass-embedded-legacy-load-done:582";