/** slices/slice.html */

.slice-attributes {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;


  .slice-attribute {
    flex: 1 1 500px;
    display: flex;
    margin-bottom: 8px;

    .name {
      flex: 0 0 100px;
      display: inline-block;
      text-align: right;

      color: $dark-gray;
      margin-right: 4px;
    }

    .value {
      flex: 1 1 auto;
      max-width: 680px;
    }
  }

}


.slice-slivers-wrapper {
  ul {
    list-style: circle;
    margin-left: 40px;
  }
}

.slice-name {
  .project-name {
    margin-left: 0.5rem;
    font-size: 1rem;

    a {
      color: $dark-gray;
    }
  }
}


.slivers-table-wrapper {
  background-color: $white;

  .slivers-table {
    width: 100%;
    table-layout: fixed;
    overflow-x: auto;

    word-break: break-all;
  }

}

.slice-renew-form-items {
  max-width: 500px;
  display: flex;

  .slice-renew-form-item {
    margin-right: 8px;
  }

}

.open-jfed-button {
  margin: 1em 0 0 1em;
  float: right;

  &:before {
    content: url('../../img/jfed-logo_16.png');
    margin-right: 4px;
  }

}
;@import "sass-embedded-legacy-load-done:606";