
.zf-tree {

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
  //font-variant: tabular-nums;
  //line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-radius: 2px;
  transition: background-color 0.3s;

  .zf-tree-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    //font-size: 14px;
    //font-variant: tabular-nums;
    //line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
  }

  .zf-tree-checkbox-wrapper:hover .zf-tree-checkbox-inner,
  .zf-tree-checkbox:hover .zf-tree-checkbox-inner,
  .zf-tree-checkbox-input:focus + .zf-tree-checkbox-inner {
    border-color: $blue-6;
  }

  .zf-tree-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $blue-6;
    border-radius: 2px;
    visibility: hidden;
    animation: antCheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: backwards;
    content: '';
  }

  .zf-tree-checkbox:hover::after,
  .zf-tree-checkbox-wrapper:hover .zf-tree-checkbox::after {
    visibility: visible;
  }

  .zf-tree-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;

    &::after {
      position: absolute;
      top: 50%;
      left: 22%;
      display: table;
      width: 5.71428571px;
      height: 9.14285714px;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(0) translate(-50%, -50%);
      opacity: 0;
      transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
      content: ' ';
    }
  }

  .zf-tree-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .zf-tree-checkbox-checked {
    .zf-tree-checkbox-inner {
      background-color: $blue-6;
      border-color: $blue-6;

      &::after {
        position: absolute;
        display: table;
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        content: ' ';
      }
    }
  }

  .zf-tree-checkbox-disabled {
    cursor: not-allowed;

    .zf-tree-checkbox-checked {
      .zf-tree-checkbox-inner::after {
        border-color: rgba(0, 0, 0, 0.25);
        animation-name: none;
      }
    }

    .zf-tree-checkbox-input {
      cursor: not-allowed;

      .zf-tree-checkbox-inner {
        background-color: #f5f5f5;
        border-color: #d9d9d9 !important;

        &::after {
          border-color: #f5f5f5;
          border-collapse: separate;
          animation-name: none;

        }
      }
    }

    & + span {
      color: $medium-gray;
      cursor: not-allowed;
    }

  }


  .zf-tree-checkbox-disabled:hover::after,
  .zf-tree-checkbox-wrapper:hover .zf-tree-checkbox-disabled::after {
    visibility: hidden;
  }

  .zf-tree-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $dark-gray;
    list-style: none;
    display: inline-block;
    cursor: pointer;

    .zf-tree-checkbox-wrapper-disabled {
      cursor: not-allowed;
    }

    & + .zf-tree-checkbox-wrapper {
      margin-left: 8px;
    }
  }

  .zf-tree-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
  }

  .zf-tree-checkbox-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    //line-height: 1.5715;
    list-style: none;
    display: inline-block;
  }

  .zf-tree-checkbox-group-item {
    display: inline-block;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    & + .zf-tree-checkbox-group-item {
      margin-left: 0;
    }

  }

  .zf-tree-checkbox-indeterminate {
    .zf-tree-checkbox-inner {
      background-color: #fff;
      border-color: #d9d9d9;
    }

    .zf-tree-checkbox-inner::after {
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      background-color: $blue-6;
      border: 0;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      content: ' ';
    }

    .zf-tree-checkbox-disabled .zf-tree-checkbox-inner::after {
      background-color: $medium-gray;
      border-color: $medium-gray;
    }
  }

  .zf-tree-focused:not(:hover):not(.zf-tree-active-focused) {
    background: #e6f7ff;
  }

  .zf-tree-list-holder-inner {
    align-items: flex-start;
  }

  .zf-tree-block-node .zf-tree-list-holder-inner {
    align-items: stretch;
  }

  .zf-tree-block-node .zf-tree-list-holder-inner .zf-tree-node-content-wrapper {
    flex: auto;
  }

  .zf-tree-treenode {
    display: flex;
    align-items: flex-start;
    padding: 0 0 4px 0;
    outline: none;
  }

  .zf-tree-treenode-disabled {
    .zf-tree-node-content-wrapper {
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;

      &:hover {
        background: transparent;
      }
    }
  }

  .zf-tree-treenode-active {
    .zf-tree-node-content-wrapper {
      background: #f5f5f5;
    }
  }

  .zf-tree-indent {
    align-self: stretch;
    white-space: nowrap;
    user-select: none;
  }

  .zf-tree-indent-unit {
    display: inline-block;
    width: 24px;
  }

  .zf-tree-switcher {
    flex: none;
    width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }

  .zf-tree-switcher .zf-tree-switcher-icon,
  .zf-tree-switcher .ant-select-tree-switcher-icon {
    font-size: 10px;
    display: inline-block;
    vertical-align: baseline;
  }

  .zf-tree-switcher .zf-tree-switcher-icon svg,
  .zf-tree-switcher .ant-select-tree-switcher-icon svg, i {
    transition: transform 0.3s;
  }

  .zf-tree-switcher-noop {
    cursor: default;
  }

  //.zf-tree-switcher_close .zf-tree-switcher-icon svg, i {
  //  -webkit-transform: rotate(-90deg);
  //  transform: rotate(-90deg);
  //}

  .zf-tree-switcher-loading-icon {
    color: $blue-6;
  }

  .zf-tree-checkbox {
    top: initial;
    margin: 4px 4px 0 0;
  }

  .zf-tree-node-content-wrapper {
    min-height: 24px;
    margin: 0;
    padding: 0 4px;
    color: inherit;
    line-height: 24px;
    background: transparent;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .zf-tree-node-content-wrapper:hover {
    background-color: $gray-2;
  }

  .zf-tree-node-content-wrapper.zf-tree-node-selected {
    background-color: $blue-1;
  }

  .zf-tree-node-content-wrapper .zf-tree-iconEle {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: top;
  }

  .zf-tree-node-content-wrapper .zf-tree-iconEle:empty {
    display: none;
  }

  .zf-tree-node-content-wrapper[draggable='true'] {
    line-height: 20px;
    border-top: 2px transparent solid;
    border-bottom: 2px transparent solid;
    user-select: none;
  }

  .zf-tree-treenode.drag-over > [draggable] {
    color: white;
    background-color: $blue-6;
    opacity: 0.8;
  }

  .zf-tree-treenode.drag-over-gap-top > [draggable] {
    border-top-color: $blue-6;
  }

  .zf-tree-treenode.drag-over-gap-bottom > [draggable] {
    border-bottom-color: $blue-6;
  }

}

.zf-tree-show-line {
  /* Motion should hide line of measure */
}

.zf-tree-show-line .zf-tree-indent-unit {
  position: relative;
  height: 100%;
}

.zf-tree-show-line .zf-tree-indent-unit::before {
  position: absolute;
  top: calc(100% - 4px);
  right: -12px;
  bottom: -28px;
  border-right: 1px solid #d9d9d9;
  content: '';
}

.zf-tree-show-line .zf-tree-indent-unit-end::before {
  display: none;
}

.zf-tree-show-line .zf-tree-treenode-motion:not(.ant-motion-collapse-leave):not(.ant-motion-collapse-appear-active) .zf-tree-indent-unit::before {
  display: none;
}

.zf-tree-show-line .zf-tree-switcher {
  z-index: 1;
  background: #fff;
}

;@import "sass-embedded-legacy-load-done:615";