
/** slices/slice-list.html */

.slices-table-wrapper {
  background-color: $white;
  padding: 24px;
}

.archived-slices-remark-wrapper {
  padding: 24px;
  text-align: center;
}

.expiring-today {
  color: get-color(alert);
  font-weight: 500;
}

.expiring-tomorrow {
  color: get-color(alert);
}

.expiring-within-week {
  color: get-color(warning);
}

.not-expiring-within-week {

}
;@import "sass-embedded-legacy-load-done:605";