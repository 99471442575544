
.login-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 24px;
}

.other-institution {
  margin-top: 1.25rem;
  font-size: 80%;

  text-align:center;
  max-width: 400px;
}

.academic-login-wrapper {
  display: flex;

  iframe {
    flex: 1 1 auto;
    min-height: 480px;
    max-width: 30em;
    border: 1px solid $light-gray;
  }
}

.academic-account-logins {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 400px));
  grid-gap: 20px;
  margin: 1em 0;


  .academic-account-login {
    display: flex;
    align-items: center;
    padding: 20px;

    color: $black;
    height: 100px;

    border-radius: 2px;
    transition: box-shadow 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);

    img.img-responsive {
      width: 70px;
      height: 70px;
      margin-right: 24px;
    }

    i.responsive-icon {
      width: 70px;
      height: 70px;
      margin-right: 24px;
      font-size: 4rem;
      color: $dark-gray;
      text-shadow: 2px 2px 2px $light-gray;
    }

    img {
      object-position: 50% 50%;
      object-fit: contain;
    }

    h3 {
      line-height: 1;
      font-size: 1.2em;
    }
  }

}
;@import "sass-embedded-legacy-load-done:580";