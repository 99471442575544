@import "settings";
@import "../base/app";

.loggedin-container {
  .loggedin-aside.collapsed {
    h2 {
      font-size: 12px;
      padding: 12px 8px;
    }
  }
}

.frontpage-main .frontpage-about-wrapper .about-logos {
  justify-content: space-around;

  .about-logo.imec {
    display: none;
  }
}

.top-bar .menu-text {
  padding: 0 1rem;

  display: flex;
  align-items: center;

  #header-image {
    display:inline;
    content: url('../../img/fed4fireplus-logo.svg');
    width: 35px;
    margin-right: 8px;
  }
}
