/** base.html */

.filter {
  margin-bottom: 24px;
}

.main-content {
  flex: 1 1 auto;
  margin: 24px auto;
  padding: 0 24px;
  max-width: 1200px;

  .main-content-wrapper {
    padding: 24px;
    background-color: $white;
  }
}

.main-container {
  display: flex;
  min-height: 100vh;

  .filters-aside {
    margin-top: 24px;
    margin-left: 24px;
    width: 400px;
    min-width: 300px;
    transition: width 0.2s ease-in;
    z-index: 10;
    min-height: 100vh;

  }

  .main-content {
    margin: 24px 0;
    max-width: initial;
  }
}


.content-title-wrapper {
  background-color: white;
  border-bottom: 1px solid $medium-gray;
}

.content-title {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;

  h2 {
    padding: 8px 0;
  }
}

.messages {
  margin: 24px auto;
  padding: 0 24px;
  display: grid;
  max-width: 960px;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  .message {
    @include callout-base;

    margin: 0;

    &.message-category-message {
      @include callout(get-color(secondary));
      margin: 0;


    }

    &.message-category-primary, .message-category-info {
      @include callout(get-color(primary));
      margin: 0;

      &:before {
        @extend %fa-icon;
        @extend .fal;
        margin-right: 10px;
        content: fa-content($fa-var-info-circle);
        color: darken(get-color(primary), 10);
        font-size: 1.2rem;
      }
    }

    &.message-category-success {
      @include callout(get-color(success));
      margin: 0;

      &:before {
        @extend %fa-icon;
        @extend .fal;
        margin-right: 10px;
        content: fa-content($fa-var-check-circle);
        color: darken(get-color(success), 10);
        font-size: 1.2rem;
      }
    }

    &.message-category-alert {
      @include callout(get-color(alert));
      margin: 0;

      &:before {
        @extend %fa-icon;
        @extend .fal;
        margin-right: 10px;
        content: fa-content($fa-var-times-circle);
        color: darken(get-color(alert), 10);
        font-size: 1.2rem;
      }
    }

    &.message-category-warning {
      @include callout(get-color(warning));
      margin: 0;

      &:before {
        @extend %fa-icon;
        @extend .fal;
        margin-right: 10px;
        content: fa-content($fa-var-exclamation-triangle);
        color: darken(get-color(alert), 10);
        font-size: 1.2rem;
      }
    }

  }
}


footer {
  text-align: center;
  margin-bottom: 1rem;

  & p {
    font-size: 0.75em;
    color: $dark-gray;
    margin-bottom: 0;
  }
}

;@import "sass-embedded-legacy-load-done:545";